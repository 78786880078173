import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserContext.js';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { addMonths, format } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { ProgressSpinner } from 'primereact/progressspinner';
import { loadTemplate, nullDashTemplate, utcToLocalTemplate } from '../components/ColumnUtil.js';
import { ExcelButton, ExportUtil } from '../utilities/ExportUtil.js';

const dateFmtStr = "yyyy-MM-dd HH:mm:ss";
const baseSort = [
	{ field: "airline", order: 1 },
	{ field: "destination", order: 1 }
];
const tooltipTxt = "Sensors used for people counting are not perfectly accurate.\n" +
	"Calculations cannot account for staff entering and exiting the bridge.\n" +
	"!! These factors allow for ~90% accuracy !!\n\n" +
	"The 'PAX Count' column is a raw summation of sensor data on flights.\n" +
	"'Seat Count' column is also a raw summation of seat numbers available on flights.\n" +
	"'Load Factor' is calculated from only flight entries with a seat count and non-zero PAX count.\n" +
	"'Load Factor Usage' is the percentage of flight entries that could be used for the calculation.\n\n" +
	"The table can be sorted by multiple columns.\n" +
	"Hold the 'ctrl' key and click a header to add a column to the sort.";

export const FlightRoute = () => {
	const [tableData, setTableData] = useState([]);
	const [filteredTableData, setFilteredTableData] = useState([]);
	const [dataLoading, setDataLoading] = useState(false);
	const [startDt, setStartDt] = useState(addMonths(new Date(), -1));
	const [endDt, setEndDt] = useState(new Date());
	const [expandedRow, setExpandedRow] = useState([]);
	const [routeDetails, setRouteDetails] = useState([]);

	const user = useContext(UserContext);

	useEffect(() => {
		fetchReportData();
	}, [])

	const fetchReportData = () => {
		setTableData([]);
		setDataLoading(true);

		const startTime = format(startDt, "yyyy-MM-dd") + " 00:00:00";
		const endTime = format(endDt, "yyyy-MM-dd") + " 23:59:59";
		const startDateStr = formatInTimeZone(new Date(startTime), "Etc/UTC", dateFmtStr);
		const endDateStr = formatInTimeZone(new Date(endTime), "Etc/UTC", dateFmtStr);

		user.apiCall('paxbyroute/' + startDateStr + 'z/' + endDateStr + 'z')
			.then((res) => {
				if (res.data != null) {
					console.log(res.data);
					setTableData(res.data);
					setFilteredTableData(res.data);
					setDataLoading(false);
				}
			});
	}

	const handleRowToggle = (e) => {
		let selectedEntry = null;
		if(expandedRow.length < 1) {
			selectedEntry = e.data;
			setExpandedRow(e.data);
		}
		else {
			selectedEntry = e.data.filter((val) =>
				val.airline !== expandedRow[0].airline ||
				val.destination !== expandedRow[0].destination
			);
			setExpandedRow(selectedEntry);
		}

		if(e.data.length === 0) {
			return;
		}

		setRouteDetails([]);
		const startTime = format(startDt, "yyyy-MM-dd") + " 00:00:00";
		const endTime = format(endDt, "yyyy-MM-dd") + " 23:59:59";
		const startDateStr = formatInTimeZone(new Date(startTime), "Etc/UTC", dateFmtStr);
		const endDateStr = formatInTimeZone(new Date(endTime), "Etc/UTC", dateFmtStr);

		let url = `routedetails/${startDateStr}Z/${endDateStr}Z`;
		user.apiCall(`${url}/${selectedEntry[0].airline}/${selectedEntry[0].destination}`)
			.then((res) => {
				setRouteDetails(res.data);
			})
	}

	const handleExport = (data) => {
		const headerOrder = [
			"airline",
			"destination",
			"peopleCount",
			"seatCount",
			"loadFactor",
			"flightCountLoad",
			"flightCount",
			"loadFactorUsage"
		];
	
		let exportData = data.map((entry) => {
			return {
				...entry,
				loadFactor:  (Math.floor(entry.loadFactor ?? 0)/100),
				loadFactorUsage:  (Math.floor(entry.loadFactorUsage ?? 0) / 100)};
		});
	
		exportData.sort((e1, e2) => {
			if (e1.airline !== e2.airline) {
				return e1.airline.localeCompare(e2.airline);
			}
			return e1.destination.localeCompare(e2.destination);
		});
	
		const fileName = `RoutePAX_${format(startDt, "yyyyMMdd")}-${format(endDt, "yyyyMMdd")}`;
		ExportUtil.exportExcel(fileName, exportData, headerOrder);
	};

	const expandedTemplate = () => (
		<>
			{routeDetails.length === 0 && (
				<ProgressSpinner />
			)}
			{routeDetails.length > 0 && (
				<DataTable value={routeDetails}
					size="small"
					sortField="scheduledBlockTime"
					sortOrder="0"
					scrollHeight="40vh"
					virtualScrollerOptions={{ itemSize: 35 }}
					scrollable
					removableSort
				>
					<Column field="scheduledBlockTime"
						header="Scheduled Time"
						body={(d) => utcToLocalTemplate(d, "scheduledBlockTime")}
						align="center"
						sortable
					/>
					<Column field="estimatedBlockTime"
						header="Estimated Time"
						body={(d) => utcToLocalTemplate(d, "estimatedBlockTime")}
						align="center"
						sortable
					/>
					<Column field="actualBlockTime"
						header="Actual Time"
						body={(d) => utcToLocalTemplate(d, "actualBlockTime")}
						align="center"
						sortable
					/>
					<Column field="gate"
						header="Gate"
						body={(d) => d.gate !== "" ? d.gate : "--"}
						sortable
					/>
					<Column field="flightNumber"
						header="Flight #"
						sortable
					/>
					<Column field="peopleCount"
						header="PAX (Est.)"
						sortable
					/>
					<Column field="seatCount"
						header="Seat Count"
						body={(d) => nullDashTemplate(d, "seatCount")}
						sortable
					/>
					<Column field="loadFactor"
						header="Load Factor"
						body={(d) => loadTemplate(d, "loadFactor")}
						sortable
					/>
					<Column field="aircraftType"
						header="Aircraft"
						sortable
					/>
				</DataTable>
			)}
		</>
	)

	return (
		<div id="RoutePax">
			<div className="date-selectors">
				<div>
					<label htmlFor="startdate">Start Date</label>
					<Calendar id="startdate"
						value={startDt}
						onChange={(e) => setStartDt(e.value)}
					/>
				</div>
				<div>
					<label htmlFor="enddate">End Date</label>
					<Calendar id="enddate"
						value={endDt}
						onChange={(e) => setEndDt(e.value)}
					/>
				</div>
				{!dataLoading && (
					<Button label="Fetch"
						onClick={fetchReportData}
					/>
				)}
				{dataLoading && (
					<ProgressSpinner />
				)}
				<Tooltip target=".tooltip-target" />
				<i className="tooltip-target pi pi-question-circle"
					data-pr-tooltip={tooltipTxt}
					data-pr-position="left"
					data-pr-at="left-388 center"
					data-pr-my="left-200 center"
				/>
			</div>
			<DataTable value={tableData}
				size="small"
				filterDisplay="row"
				onRowToggle={handleRowToggle}
				rowExpansionTemplate={expandedTemplate}
				onValueChange={(flt) => setFilteredTableData(flt)}
				expandedRows={expandedRow}
				footer={<ExcelButton handleExport={() => handleExport(filteredTableData)} />}
				multiSortMeta={baseSort}
				sortMode="multiple"
				virtualScrollerOptions={{ itemSize: 45 }}
				scrollHeight="80vh"
				scrollable
			>
				<Column expander />
				<Column field="airline"
					header="Airline"
					showFilterMenu={false}
					filterMatchMode="contains"
					filter
					sortable
				/>
				<Column field="destination"
					header="Partner Airport"
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="flightType"
					header="Arr | Dep"
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="peopleCount"
					header="PAX Count"
					body={(d) => d.peopleCount.toLocaleString()}
					sortable
				/>
				<Column field="seatCount"
					header="Seat Count"
					body={(d) => d.seatCount.toLocaleString()}
					sortable
				/>
				<Column field="loadFactor"
					header="Load Factor"
					body={(d) => loadTemplate(d, "loadFactor")}
					style={{width: "130px"}}
					sortable
				/>
				<Column field="flightCountLoad"
					header="Load Factor Flights"
					sortable
				/>
				<Column field="flightCount"
					header="Total Flights"
					sortable
				/>
				<Column field="loadFactorUsage"
					header="Load Factor Usage"
					body={(d) => loadTemplate(d, "loadFactorUsage")}
					style={{width: "160px"}}
					sortable
				/>
			</DataTable>
		</div>
	);
}